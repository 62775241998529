var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        closable: true,
        title: _vm.modalTitle,
        size: "normal",
        visible: _vm.visible,
        "mask-closable": false,
        "ok-text": "保存",
      },
      on: {
        ok: _vm.handleOk,
        cancel: _vm.handleCancel,
        afterClose: _vm.afterClose,
      },
    },
    [
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "职务名称", prop: "dutyName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入1-20个字符" },
                model: {
                  value: _vm.form.dutyName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "dutyName", $$v)
                  },
                  expression: "form.dutyName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "职务描述", prop: "dutyDescribe" } },
            [
              _c("a-input", {
                attrs: {
                  type: "textarea",
                  "max-length": 200,
                  placeholder: "请输入0-200个字符",
                  "auto-size": { minRows: 3, maxRows: 7 },
                },
                model: {
                  value: _vm.form.dutyDescribe,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "dutyDescribe", $$v)
                  },
                  expression: "form.dutyDescribe",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "能力要求", prop: "capacityDemand" } },
            [
              _c("a-input", {
                attrs: {
                  "max-length": 200,
                  type: "textarea",
                  placeholder: "请输入0-200个字符",
                  "auto-size": { minRows: 3, maxRows: 7 },
                },
                model: {
                  value: _vm.form.capacityDemand,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "capacityDemand", $$v)
                  },
                  expression: "form.capacityDemand",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }